import React from 'react'
import styled from 'styled-components'
import TranslatedText from '../TranslatedText'
import { rgba } from 'polished'

const Nav: React.FC = () => {
  return (
    <StyledNav>
      <StyledAbsoluteLink href="/#swap" className="active" rel="noopener noreferrer">
        <TranslatedText translationId={8}>Swap</TranslatedText>
      </StyledAbsoluteLink>
      <StyledAbsoluteLink href="/#pool" rel="noopener noreferrer">
        <TranslatedText translationId={2}>Pool</TranslatedText>
      </StyledAbsoluteLink>
      <StyledAbsoluteLink href="https://mainnet-explorer.grokchain.dev/" rel="noopener noreferrer">
        <TranslatedText translationId={4}>Explorer Scan</TranslatedText>
      </StyledAbsoluteLink>
      <StyledAbsoluteLink href="https://www.dextools.io/app/en/ether/pair-explorer/0xd3927981cafa537391a67a26d114d3d5a72098ff" rel="noopener noreferrer">
        <TranslatedText translationId={4}>Chart & Info</TranslatedText>
      </StyledAbsoluteLink>
    </StyledNav>
  )
}

const StyledNav = styled.nav`
  align-items: center;
  display: flex;
  font-size: 18px;
  line-height: 45px;
  font-weight: 650;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 600px) {
  display: none;
  }
`

const StyledAbsoluteLink = styled.a`
  color: ${({ theme }) => theme.colors.text1};
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.text1};
  }
  @media (max-width: 400px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (prefers-color-scheme: dark) {
    color: ${({ theme }) => theme.colors.text1};
    &:hover {
      color: ${({ theme }) => theme.colors.text1};
    }
    &::before {
      background-color: ${({ theme }) => rgba(theme.colors.text1, 0.5)};
    }
  }
`

export default Nav
